import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize(process.env.GA_ID, {
    debug: false,
    gaOptions: {
      allowLinker: true,
    },
  });
  ReactGA.ga("require", "linker");
  ReactGA.ga("linker:autoLink", ["dexecure.com"]);
};

export const setUserGA = (userId) => {
  ReactGA.set({
    userId,
  });
};

export const logPageView = (pageName = window.location.pathname) => {
  ReactGA.set({ page: pageName });
  ReactGA.pageview(pageName);
};

export const gtag = (...args) => {
  args.forEach((arg) => ReactGA.event(arg));
};

export default undefined;
